import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/wqmatadourobovinos.css'
import pal1 from "../images/palestrantes/pj-budler.png" // Tell webpack this JS file uses this image
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import pal4 from "../images/palestrantes/phill.jpg" // Tell webpack this JS file uses this image
import pal5 from "../images/palestrantes/roberto.jpg" // Tell webpack this JS file uses this image
import pal6 from "../images/palestrantes/stilwell.jpg" // Tell webpack this JS file uses this image
import pal7 from "../images/palestrantes/rui.jpg" // Tell webpack this JS file uses this image
import pal8 from "../images/palestrantes/xavier.jpg" // Tell webpack this JS file uses this image
import pal9 from "../images/palestrantes/fabio.jpg" // Tell webpack this JS file uses this image
import pal10 from "../images/palestrantes/xenia.jpg" // Tell webpack this JS file uses this image
import pal11 from "../images/palestrantes/fabio2.jpg" // Tell webpack this JS file uses this image
import pal12 from "../images/palestrantes/jaime.png" // Tell webpack this JS file uses this image
import bullet from "../images/bullet.png" // Tell webpack this JS file uses this image
import alvo from "../images/alvo2.png" // Tell webpack this JS file uses this image
import Footer from "../components/footer"
import fotomasterclass from "../images/imagem-masterclass.jpg" // Tell webpack this JS file uses this image
import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import logo3 from "../images/logos/georgia.png" // Tell webpack this JS file uses this image
import logo4 from "../images/logos/texastech.png" // Tell webpack this JS file uses this image
import logo5 from "../images/logos/fmv.png" // Tell webpack this JS file uses this image
import logovivid from "../images/logos/vivid.png" // Tell webpack this JS file uses this image
import logovividfarms from "../images/logos/vividfarms.png" // Tell webpack this JS file uses this image
import fotosobrenos from "../images/fotoscursos/foto-sobrenos.jpg" // Tell webpack this JS file uses this image

import logofarmin from "../images/logos/farmin.png" // Tell webpack this JS file uses this image

import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image
import foto6 from "../images/fotoscursos/foto32.jpg" // Tell webpack this JS file uses this image
import foto7 from "../images/fotoscursos/foto33.jpg" // Tell webpack this JS file uses this image

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';

var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
var settingsMobile = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
const wqmatadouros = () => (
  <Layout>
    <div id="section-1">
      <div className="wrapper">
      <div className="col1">
          <div className="contentorgeral-masterclass">
            <div className="esgotado-texto">
              ESGOTADO
            </div>
            <div className="texto inter-bold">
             <span className="opacity">Pastoreio total <br></br> e seleção</span> <br></br>
             da vacada a erva
            </div>
            <div className="logos">
            {/* <img src={logofarmin} alt="" /> */}
            <img src={logovivid} alt="" />
            <img src={logovividfarms} alt="" />

                  
                  

  
                    </div>
          </div>
      </div>
          <div className="col2">
            <div className="contentorgeral">
              <div className="masterclass-id">
                <div className="col-left">
               

                  <div className="palestrante">
                    <div className="foto">
                    <img className="imagem" src={pal12} alt="" />
                    </div>
                    <div className="texto ">
                      <div className="nome inter-bold">
                      Eng. Jaime Elizondo Braun
                      </div>
                    
                    <div className="titulo inter-light">
                    Consultor e produtor de bovinos
                      </div>
                      </div>
                    
                  </div>
              
                </div>
                <div className="col-right inter-regular">
                    <div className="local">
                      <div className="icone">
                      <RoomIcon></RoomIcon>
                      </div>
                      <div className="text">
                      Tomar, Portugal
                      </div>
                    
                    </div>
                    <div className="data">
                      <div className="icone">
                      <TodayIcon></TodayIcon>
                      </div>
                      <div className="text">
                      18 e 19 de Novembro de 2022
                      </div>
                    
                    </div>
                    <div className="lingua">
                      <div className="icone">
                      <ChatBubbleIcon></ChatBubbleIcon>
                      </div>
                      <div className="text">
                      Espanhol
                      </div>
                    
                    </div>
                    <div className="preco">
                      <div className="icone">
                      <EuroIcon></EuroIcon>
                      </div>
                      <div className="text">
                      600€ +IVA
                      </div>
                    
                    </div>
                  </div>
           
              </div>
              <div className="contentorgeral-btns">
                <div className="brochure">
                <a href="https://farmin-trainings.net/brochuras/pastoreio-maneio-adaptados-extensivo.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button> 
                </a>
                </div>
                {/* <div className="form">
                <a href="https://forms.gle/rvK1RtJf2U573xTR9" target="_blank" >
                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
                </div> */}
              </div>
            </div>
          </div>
        
      </div>
    </div>

    <div id="section-2-masterclass">
      <div className="wrapper-fixed">
      <img src={fotosobrenos} alt="" />

        </div>
      <div className="wrapper">
        <div className="wrapper-col-1">
          <div className="wrapper-text">
            <div className="subtitle">
              Tópicos
              </div>
            <div className="title inter-black">
              Sobre o Curso
              </div>
          
       
          
              <div className="desc inter-light">
              Com 40 anos de experiência, Jaime Elizondo Braun virá a Portugal explicar o método Real Wealth Ranching que está direcionado para alcançar a rentabilidade máxima em regimes de produção extensivos com maximização do sequestro de carbono e regeneração de solos e ecossistemas agrícolas.<br></br>
Nos tempos que correm, em que os preços das matérias primas e fertilizantes atingem valores record, o caminho que indica Elizondo alicerçado em métodos biológicos de baixo custo baseados na gestão do pastoreio e selecção adaptada é, mais do que nunca, relevante.<br></br>
<br></br>
Os dois pilares deste curso são:<br></br>

                </div>
          
           
                <div className="desc inter-light">
              <div className="bullet">
              <img src={bullet} alt="" />
              </div>
              <div className="text-bullet">
              “Pastoreio Total” que visa aumentar o encabeçamento em regimes a erva ano após ano, maximizar a rentabilidade e melhorar os solos
              </div>
                </div>

                <div className="desc inter-light">
              <div className="bullet">
              <img src={bullet} alt="" />
              </div>
              <div className="text-bullet">
              Seleção e Maneio Reprodutivo Inteligente da Vacada a Erva” que visam criar gado de baixa manutenção com alta resistência, funcionais e eficientes na conversão, permitindo um dia-a-dia na exploração mais fácil e calmo
              </div>
                </div>
                
               
            </div>

          
          </div>
          <div className="wrapper-col-2">
          </div>
      </div>
    

    </div>
    <div id="section-publico-alvo">
    <div className="wrapper">
        <div className="wrapper-col-1">
    <div className="wrapper-text">
    <div className="conteudo">
         <div className="title inter-black">
           Público-alvo
           </div>
           

           
           <div className="desc1 inter-light">
           <div className="bullet">
           <img src={alvo} alt="" />
           </div>
           <div className="text-bullet">
           Produtores de bovinos em regime extensivo
           </div>
             </div>
             <div className="desc inter-light">
           <div className="bullet">
           <img src={alvo} alt="" />
           </div>
           <div className="text-bullet">
           Engenheiros zootécnicos, agrónomos e veterinários
           </div>
             </div>
             <div className="desc inter-light">
           <div className="bullet">
           <img src={alvo} alt="" />
           </div>
           <div className="text-bullet">
           Consultores

           </div>
             </div>
             <div className="desc inter-light">
           <div className="bullet">
           <img src={alvo} alt="" />
           </div>
           <div className="text-bullet">
           Entusiastas e estudantes
           </div>
             </div>
      
             </div>
      
     

             <div className="logos">
               
             {/* <img src={logo1} alt="" />
             <img src={logo2} alt="" /> */}

               </div>
         </div>
         </div>
         </div>
    </div>
    <div id="section-program-matadouro-bovinos">
    <div className="wrapper">
          <div className="titulo inter-bold">
            Programa 
          </div>
       
      </div>
      <div className="wrapper-program">
      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                        <Accordion.Header>
                              
                            + Dia 1 - 18/11/2022
                            </Accordion.Header>

                            <Accordion.Body>
                               
                            <div class="modulo">
                                <p><span className="date">08:30 - 10:30</span> | <span class="mod">Módulo 1</span> - <span class="txt">Porquê o programa de pastoreio total? | Pastoreio na estação verde</span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">10:30 - 11:00</span> | <span className="mod">Coffee-break</span></p>
                                </div>
        
                                <div class="modulo">
                                  <p><span className="date">11:00 - 13:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">Pastoreio na estação seca</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:00</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:00 - 16:00</span> | <span className="mod">Módulo 3</span> - <span class="txt">Nutrição</span></p>
                                </div>
   
                             
                                <div class="modulo">
                                  <p><span className="date">16:00 - 18:00</span> | <span className="mod">Módulo 4</span> - <span class="txt">Infra-estruturas mínimas</span></p>
                                </div>
          
                    

                                
                                </Accordion.Body>

                                </Accordion.Item>
                        

                                <Accordion.Item eventKey="1">
                        <Accordion.Header>
                            +   Dia 2 - 19/11/2022
                            </Accordion.Header>

                            <Accordion.Body>
                            <div class="modulo">
                                <p><span className="date">08:30 - 10:30</span> | <span class="mod">Módulo 1</span> - <span class="txt">A necessidade de um animal melhor | DEPs, porque precisamos da nossa própria observação | Abordagens incorrectas na selecção | A fertilidade em regime extensivo é fortemente hereditária? | A verdadeira eficiência da conversão de forragens
</span></p>
                                </div> 
                                <div class="modulo">
                                  <p><span className="date">10:30 - 11:00</span> | <span className="mod">Coffee-break</span></p>
                                </div>
        
                                <div class="modulo">
                                  <p><span className="date">11:00 - 13:00</span> | <span className="mod">Módulo 2</span> - <span class="txt">O que há de errado com o crescente aumento do tamanho do gado? | Seleccionar de acordo com a natureza sem a contradizer | Condição corporal fortemente hereditária | Observação visual, perfil de animal super-compacto | Equilíbrio hormonal | Características secundárias masculinas

</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">13:00 - 14:00</span> | <span className="mod">Almoço</span></p>
                                </div>
                                <div class="modulo">
                                  <p><span className="date">14:00 - 16:00</span> | <span className="mod">Módulo 3</span> - <span class="txt">Características secundárias masculinas |  Adaptação climática | Adaptação nutricional | Resistência a parasitas e doenças | Relação carne/osso | Facilidade de parto


</span></p>
                                </div>
   
                             
                                <div class="modulo">
                                  <p><span className="date">16:00 - 18:00</span> | <span className="mod">Módulo 4</span> - <span class="txt">A importância da longevidade | Porque é que a selecção de touros é tão importante | Como melhorar mais rapidamente a nossa genética usando os nossos próprios | touros devidamente seleccionados | Como seleccionar os nossos próprios touros de 2-12 meses de idade | Como seleccionar os nossos próprios touros +14 meses de idade | Condição corporal, o principal determinante da fertilidade feminina | Como seleccionar as futuras mães de touros
</span></p>
                                </div>
          
                                </Accordion.Body>
                                </Accordion.Item>

                                
                           
                      
                    
                          
                             
    
               
                    </Accordion>
   
        </div>
        <div className="wrapper-btns">
        <div className="brochure">
        <a href="https://farmin-trainings.net/brochuras/pastoreio-maneio-adaptados-extensivo.pdf" target="_blank" >

                <button className="btn-brochure inter-bold">Brochura</button>
                </a> 
                </div>
                {/* <div className="form">
                <a href="https://forms.gle/rvK1RtJf2U573xTR9" target="_blank" >
                <button className="btn-form inter-bold">Inscrição</button> 
                </a>
          </div> */}
          </div>
    </div>

    <div id="section-palestrantes">
      <div className="wrapper">
          <div className="titulo inter-bold">
            Palestrantes
          </div>
          <div className="text inter-regular">
          O curso intensivo conta com a presença das mentes mais brilhantes de cada sector. 
            </div>
      </div>
      <div className="wrapper-speakers">
      

        <div className="col1">
        <div className="cartao-speaker">
          <div className="header">
              <div className="foto">
              <img src={pal12} alt="" />

              </div>
              <div className="nome">
              Eng. Jaime Elizondo Braun
              </div>
              <div className="titulo">
              Consultor e produtor de bovinos 
                              </div>
          </div>
          <div className="body">
              <div className="portfolio">
                  <div className="linha">
                  <p><span className="icone"><CheckIcon></CheckIcon></span>Agronomo, zootécnico e produtor de bovinos na Florida, EUA
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  Consultor internacional de gestão de vacadas em extensivo e seleção adaptada
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span>

                  Especialista em sistemas de pastoreio regenerativo</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
                  Criador do curso "Pastoreio Total" - dado nos EUA, México, Colômbia, França e Itália.
</p>
                  <p><span className="icone"><CheckIcon></CheckIcon></span> 

                  Criador do método de gestão de vacadas "Real Wealth Ranching"</p>

                  <p><span className="icone"><CheckIcon></CheckIcon></span> 
                  
                  Autor do livro de sucesso "Manejo Regenerativo de Ranchos "
                  </p>
               
                  </div>
              </div>
            </div>
        </div>
        </div>
   
      </div>
     

    </div>

    <div id="section-fotos">
      <Slider {...settings}>
      <div>
        <img src={foto6} alt="" />
      </div>
      <div>
        <img src={foto7} alt="" />
      </div>
      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    <div id="section-fotos-mobile">
      <Slider {...settingsMobile}>

      <div>
        <img src={foto1} alt="" />
      </div>
      <div>
      <img src={foto2} alt="" />
      </div>
      <div >
      <img src={foto3} alt="" />
      </div>
      <div >
      <img src={foto4} alt="" />
      </div>
      <div >
      <img src={foto5} alt="" />
      </div>
      </Slider>
      
    </div>
    {/* <div id="contact-section">    
   <div className="wrapper-nl">

   
      <div className="nl-col1">
         <div className="body-contact">
            <Form />
         </div>
      </div>
   <div className="nl-col2">
   <img className="newsletter-img" src={newsletter} alt="" />

   </div>
   </div>
   
   
   
  
   </div> */}
    <Footer/>

  </Layout>
)

export default wqmatadouros
